<template>
  <div class="pageContol organizationList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">机构列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="seachStyle">
          <el-row :gutter="20">
            <el-col :sm="12" :md="8" :lg="5" class="searchList">
              <span class="listLabel">机构名称:</span>

              <el-select
                class="listInput"
                size="small"
                v-model="searchData.compId"
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
              >
                <el-option
                  v-for="item in companyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </el-col>
            <el-col :sm="12" :md="8" :lg="5" class="searchList">
              <span class="listLabel">行政区划:</span>
              <el-cascader
                clearable
                v-model="searchData.areaId"
                :options="areatreeList"
                :props="propsarea"
                size="small"
                class="listInput"
              ></el-cascader>
            </el-col>

            <el-col :sm="12" :md="8" :lg="5" class="searchList">
              <div class="listBtn">
                <el-button type="primary" class="listBtnS" round @click="getData()">查询</el-button>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
              <el-table-column
                label="机构名称"
                align="left"
                show-overflow-tooltip
                prop="compName"
                minWidth="150px"
              />

              <el-table-column label="行政区划" align="left" show-overflow-tooltip prop="areaNamePath"  minWidth="120px" />
              <el-table-column label="开班总数" align="right" show-overflow-tooltip prop="projectNum" />
              <el-table-column label="培训学员总数" align="right" show-overflow-tooltip prop="userNum" />
              <el-table-column label="操作" align="center" width="100px" fixed="right">
                <div slot-scope="scope" class="flexcc">
                  <el-button type="text" size="mini" @click="open(scope.row.compId)">开班记录</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";

export default {
  name: "sjzRegulator/organizationList",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      companyList: [],
      areatreeList: [], //行政区域list
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true
      },
      searchData: {
        compId: "",
        areaId: ""
      }
    };
  },
  created() {},
  watch: {},
  computed: {},
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getareatree();
      this.getTableHeight();
      this.getData(-1);
    },
    //机构列表
    toOrganizationList() {
      this.$router.push({
        path: "/web/SupervisionEnd/sjzRegulator/organizationList"
      });
    },
    //跳转开班统计
    open(compId) {
      this.$router.push({
        path: "/web/SupervisionEnd/sjzRegulator/startClassRecord",
        query: {
          compId: compId
        }
      });
    },
    getData(pageNum = 1) {
      const params = {
        pageSize: this.pageSize
      };
      if (this.searchData.compId) {
        params.compId = this.searchData.compId;
      }
      if (this.searchData.areaId) {
        params.areaId = this.searchData.areaId;
      }
      this.doFetch({
        url: "/gov/roll/call/company/page-list",
        params,
        pageNum
      });
    },
    //获取行政区划
    getareatree() {
      this.$post("/gov/area/tree").then(ret => {
        this.areatreeList = ret.data;
      });
    },
    // 资源提供者1
    getCompanyList(query) {
      this.$post("/sys/company/queryCompanyList", { compName: query })
        .then(res => {
          if (res.status == 0) {
            this.companyList = res.data || [];
          } else {
            this.companyList = [];
          }
        })
        .catch(() => {
          return;
        });
    },

    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 3.25) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
    }
  }
};
</script>
<style lang="less">
</style>
